@media screen and (min-width: 768px) {
    .aboutus{
        display: flex;
        padding-top: 100px;
        height: 140vh;
        background-color: #e5e5f7;
    opacity: 1;
    background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, #e5e5f7 40px ), repeating-linear-gradient( #f4f4f655, #f4f4f6 );
        .founderpic{
            width: 40%;
            img{
                width: 100%;
            }
        }
        .aboutmana{
            padding-left: 80px;
            flex: 70%;
            position: relative;
            h1{
                
              color:#1f2f75;
            }
            .blocks{
                display: flex;  
                position: absolute;
                left: -10%;
    
            }
            .vision{
                background-color: #e5e5f7;
                border: 5px solid #b8b3b3;
                width: fit-content; 
                padding-left: 20px;
                padding-right: 20px;
                margin-right: 50px;
                p{
                    font-size: 19px;
                }
            }
            .mission{
                background-color: #e5e5f7;
                border: 5px solid #b8b3b3;
                width: fit-content;
                padding-left: 20px;
                padding-right: 20px;
                margin-right: 50px;
                p{
                    font-size: 19px;
                }
            }
        }
    }
    
    
}

@media only screen and (max-width: 767px) {
    .aboutus{
        padding-top: 10vh;
        height: fit-content;
        background-color: #e5e5f7;
        opacity: 1;
        background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, #e5e5f7 40px ), repeating-linear-gradient( #f4f4f655, #f4f4f6 );
        .founderpic{
            img{
                width: 100%;
            }
        }
        .aboutmana{
            padding: 10px;
            h1{
            text-align: center;
                
              color:#1f2f75;
            }
            .vision{
                text-align: center;
                background-color: #e5e5f7;
                border: 2px solid #b8b3b3;
                width: fit-content; 
                // padding-left: 20px;
                // padding-right: 20px;
                margin-bottom: 10px;
                p{
                    font-size: 16px;
                }
            }
            .mission{
                background-color: #e5e5f7;
                border: 2px solid #b8b3b3;
                width: fit-content;
                // padding-left: 20px;
                // padding-right: 20px;
                // margin-right: 50px;
                p{
                    font-size: 19px;
                }
            }
        }
    }
    
    
}  

