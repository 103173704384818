@media screen and (min-width: 768px) {
  .slider{
    flex: 50%;
  }
  
  .img1{
    height: 80vh; 
  }
   
  .img2{
    height: 80vh;
  }
  
  .img3{
    height: 80vh;
  }
}

@media only screen and (max-width: 767px) {

  
  .img1{
    height: 30vh; 
  }
   
  .img2{
    height: 30vh;
  }
  
  .img3{
    height: 30vh;
  }
}  

