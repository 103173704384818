@media screen and (min-width: 768px) {
  .form-main {
    text-align: center;
    background-color: transparent;
    height: 105vh;
    background-color: #dee4e9;
    display: flex;
    h1 {
      font-size: 30px;
      font-family: "Lucida Sans";
      font-weight: bold;
      color: #141519;
      margin-top: 70px;
    }
    p {
      font-size: 15px;
      letter-spacing: 0.7px;
      color: rgb(255, 255, 255);
      color: #595a5c;
    }
  }

  .leftt {
    flex: 40;
    img {
      height: 100%;
      width: 100%;
    }
  }

  .rightt {
    flex: 60;
    .form {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 50vw;
      input {
        padding: 15px;
        border: none;
        font-size: 18px;
        border-radius: 10px;
        outline: none;
        background-color: #f2f4f5;
      }
      select {
        padding: 15px;
        border: none;
        font-size: 18px;
        border-radius: 10px;
        outline: none;
        background-color: #f2f4f5;
      }
      textarea {
        padding: 15px;
        font-size: 18px;
        background-color: #f2f4f5;
        outline: none;
        border-radius: 20px;
      }
      .flexhere {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        #name {
          width: 50%;
          margin-right: 2px;
        }
        #email {
          width: 50%;
          margin-left: 2px;
        }
      }

      #tele {
        width: 100%;
        margin-top: 5px;
      }
      #project {
        display: block;
        width: 100%;
        margin-top: 5px;
      }
      #msg {
        width: 100%;
        margin-top: 5px;
        height: 100px;
        border-radius: 10px;
      }
      p {
        text-align: left;
        .bold {
          font-weight: bold;
        }
      }

      button {
        color: rgba(0, 0, 0, 0.719);
        text-align: center;
        font-size: 20px;
        background-color: #facd39;
        border: none;
        cursor: pointer;
        border-radius: 4px;
        letter-spacing: 1px;
        width: 100%;
        padding: 18px;
        margin-top: 10px;
        font-weight: bold;
      }
      button:hover {
        border-color: #facd39b7;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .form-main {
    text-align: center;
    background-color: transparent;
    height: 80vh;
    background-color: #dee4e9;
    padding-top: 2px;
    h1 {
      font-size: 30px;
      font-family: "Lucida Sans";
      font-weight: bold;
      color: #141519;
      margin-top: 70px;
    }
    p {
      font-size: 15px;
      letter-spacing: 0.7px;
      color: rgb(255, 255, 255);
      color: #595a5c;
    }
  }

  .leftt {
    display: none;
  }

  .rightt {
    .form {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 90vw;
      input {
        padding: 15px;
        border: none;
        font-size: 18px;
        border-radius: 10px;
        outline: none;
        background-color: #f2f4f5;
      }
      select {
        padding: 15px;
        border: none;
        font-size: 18px;
        border-radius: 10px;
        outline: none;
        background-color: #f2f4f5;
      }
      textarea {
        padding: 15px;
        font-size: 18px;
        background-color: #f2f4f5;
        outline: none;
        border-radius: 20px;
      }
      .flexhere {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        #name {
          width: 50%;
          margin-right: 2px;
        }
        #email {
          width: 50%;
          margin-left: 2px;
        }
      }

      #tele {
        width: 100%;
        margin-top: 5px;
      }
      #project {
        display: block;
        width: 100%;
        margin-top: 5px;
      }
      #msg {
        width: 100%;
        margin-top: 5px;
        height: 100px;
        border-radius: 10px;
      }
      p {
        text-align: left;
        .bold {
          font-weight: bold;
        }
      }

      button {
        color: rgba(0, 0, 0, 0.719);
        text-align: center;
        font-size: 20px;
        background-color: #facd39;
        border: none;
        cursor: pointer;
        border-radius: 4px;
        letter-spacing: 1px;
        width: 100%;
        padding: 18px;
        margin-top: 10px;
        font-weight: bold;
      }
      button:hover {
        border-color: #facd39b7;
      }
    }
  }
}
