@media screen and (min-width: 768px) {
    .mobgal{
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .ongoinglist{
        display: none;
    }
    .mobgal{
        .carddd{
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            width: fit-content;
            background-color: rgb(58, 155, 168);
            border-radius: 20px;
            margin-bottom: 10px;
            h1{
                margin: 0;
                font-size: 18px;
                color: rgba(255, 255, 255, 0.753);
            }
            img{
                width: 90vw;
            border-radius: 20px;

            }
        }
    }
}  