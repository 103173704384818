@media screen and (min-width: 768px) {
    .testimonial{
        background-color: #f7fafc;
        border-radius: 60px;
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        height: 40vh;
        padding: 40px;
        color: rgb(46, 44, 44);
    
        .testLeft{
            display: flex; 
            flex: 50%;
            .testiImg{
                flex: 30%;
                text-align: center;
                margin-top: 40px;
                img{
                    width: 150px;
                    border-radius: 10px;
                }
            }
            .testiDetail{
                flex: 70%;
                .testitop{
                    height: 60%;
                    .texti{
                        font-size: 18px;
                        font-weight: bold;
                    }
                }
                .testibottom{
                    height: 40%;
                    padding-top: 20px;
                    .name{
                        margin-bottom: 0;
                        color: #2b6cb0;
                    }
                    .role{
                        margin-top: 0;
                    }
                }
            }
        }
        .testRight{
            display: flex;
            flex: 50%;
            .testiImg{
                flex: 30%;
                text-align: center;
                margin-top: 40px;
                img{
                    width: 150px;
                    border-radius: 10px;
                }
            }
            .testiDetail{
                flex: 70%;
                .testitop{
                    height: 60%;
                    .texti{
                        font-size: 18px;
                        font-weight: bold;
                    }
                }
                .testibottom{
                    height: 40%;
                    padding-top: 20px;
                    .name{
                        margin-bottom: 0;
                        color: #2b6cb0;
                    }
                    .role{
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .testimonial{
        background-color: #f7fafc;
        border-radius: 60px;
        margin-top: 20px;
        margin-bottom: 20px;
        height: fit-content;
        padding: 20px;
        color: rgb(46, 44, 44);
    
        .testLeft{
            display: flex; 
            
            .testiImg{
                flex: 50%;
                text-align: center;
                margin-top: 40px;
                img{
                    width: 150px;
                    border-radius: 10px;
                }
            }
            .testiDetail{
                flex: 50%;
                .testitop{
                    height: 60%;
                    .texti{
                        font-size: 10px;
                        font-weight: bold;
                    }
                }
                .testibottom{
                    height: 40%;
                    // padding-top: 20px;
                    .name{
                        margin-bottom: 0;
                        color: #2b6cb0;
                    }
                    .role{
                        margin-top: 0;
                    }
                }
            }
        }
        .testRight{
            display: flex;
            .testiImg{
                flex: 50%;
                text-align: center;
                margin-top: 40px;
                img{
                    width: 150px;
                    border-radius: 10px;
                }
            }
            .testiDetail{
                flex: 50%;
                .testitop{
                    height: 60%;
                    .texti{
                        font-size: 10px;
                        font-weight: bold;
                    }
                }
                .testibottom{
                    height: 40%;
                    // padding-top: 20px;
                    .name{
                        margin-bottom: 0;
                        color: #2b6cb0;
                    }
                    .role{
                        margin-top: 0;
                    }
                }
            }
        }
    }
}  

