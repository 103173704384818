
@media screen and (min-width: 768px) {
    .margin{
        padding-top: 100px;
        display: flex;
        .contactinfo{ 
            color: white;
            flex: 40;
            background-color: #3e2093;
            padding: 10px;
            padding-left: 60px;
            padding-right: 40px;
            background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 width=%22762.464%22 height=%22745.463%22 viewBox=%22107.499 119.144 762.464 745.463%22%3E%3Cdefs%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M814.5 680Q708 860 497.5 864.5t-330-180Q48 500 166.5 314T505 119.5q220-8.5 318 186T814.5 680Z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22%23fa949d%22 d=%22M814.5 680Q708 860 497.5 864.5t-330-180Q48 500 166.5 314T505 119.5q220-8.5 318 186T814.5 680Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"), url("data:image/svg+xml;utf8,%3Csvg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 width=%22762.464%22 height=%22745.463%22 viewBox=%22107.499 119.144 762.464 745.463%22%3E%3Cdefs%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M814.5 680Q708 860 497.5 864.5t-330-180Q48 500 166.5 314T505 119.5q220-8.5 318 186T814.5 680Z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22%237e53f9%22 d=%22M814.5 680Q708 860 497.5 864.5t-330-180Q48 500 166.5 314T505 119.5q220-8.5 318 186T814.5 680Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
            background-size: 300px 300px, 200px 200px;
            background-position: 120% 150%, 80% 105%;
            background-repeat: no-repeat;
            padding-top: 50px;
            .sus{
                color: #bab1d8;
                font-size: 20px;
            }
            .condet{
                margin-top: 30px;
                p{
                    color: #f5f4fa;
                    font-size: 18px;
                    margin-bottom: 40px;
                }
            }
    
    
        }
        .formm{
            flex: 60;
        }
    }
    
}

@media only screen and (max-width: 767px) {
    .margin{
        padding-top: 10vh;
        // display: flex;
        .contactinfo{ 
            font-family: 'Manrope', sans-serif;
            color: white;
            flex: 40;
            background-color: #3e2093;
            padding: 10px;
            padding-left: 60px;
            padding-right: 40px;
            background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 width=%22762.464%22 height=%22745.463%22 viewBox=%22107.499 119.144 762.464 745.463%22%3E%3Cdefs%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M814.5 680Q708 860 497.5 864.5t-330-180Q48 500 166.5 314T505 119.5q220-8.5 318 186T814.5 680Z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22%23fa949d%22 d=%22M814.5 680Q708 860 497.5 864.5t-330-180Q48 500 166.5 314T505 119.5q220-8.5 318 186T814.5 680Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"), url("data:image/svg+xml;utf8,%3Csvg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 width=%22762.464%22 height=%22745.463%22 viewBox=%22107.499 119.144 762.464 745.463%22%3E%3Cdefs%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M814.5 680Q708 860 497.5 864.5t-330-180Q48 500 166.5 314T505 119.5q220-8.5 318 186T814.5 680Z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22%237e53f9%22 d=%22M814.5 680Q708 860 497.5 864.5t-330-180Q48 500 166.5 314T505 119.5q220-8.5 318 186T814.5 680Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
            background-size: 300px 300px, 200px 200px;
            background-position: 120% 150%, 80% 105%;
            background-repeat: no-repeat;
            padding-top: 50px;
            h1{
                font-size: 30px;
                text-align: center;
            }
            .sus{
                color: #bab1d8;
                font-size: 16px;
            }
            .condet{
                margin-top: 30px;
                p{
                    color: #f5f4fa;
                    font-size: 20px;
                    margin-bottom: 40px;
                }
            }
    
    
        }
        .formm{
            flex: 60;
        }
    }
    
}  


