@media screen and (min-width: 768px) {
  .projectProfile {
    height: 100vh;
    background-color: #f7fafc;
    background-color: #f6f0e0;
    border-radius: 60px;
    padding-top: 40px;
    margin-top: 20px;
    .title {
      text-align: center;
      font-size: 25px;
      margin-bottom: 0;
      padding-top: 0px;
    }
    hr {
      width: 80px;
      background: #f9df35;
      height: 2px;
      border: none;
    }

    .profile {
      display: flex;
      width: 95%;
      margin-left: auto;
      margin-right: auto;
      height: 80vh;
      background-color: inherit;
      padding-left: 20px;
      padding-right: 20px;
      border-radius: 30px;
      .left {
        flex: 50%;
        margin-right: 30px;
        margin-top: auto;
        margin-bottom: auto;
        .projectlogo {
          margin-bottom: 20px;
          img {
            width: 200px;
            margin-left: 50%;
            transform: translate(-50%);
          }
        }
        .img1 {
          height: 55vh;
        }
      }
      .right {
        flex: 50%;
        margin-top: auto;
        margin-bottom: auto;
        h2 {
          margin-bottom: 0;
          font-family: "Cormorant Garamond", serif;
          font-size: 28px;
          font-weight: bold;
        }
        .ptext {
          color: gray;
          font-size: 17px;
          letter-spacing: 0.5px;
        }
        .tabFont {
          letter-spacing: 1px;
          font-family: "Manrope", sans-serif;
          font-weight: bold;
          font-size: 16px;
          margin-left: 10px;
          margin-top: 5px;
        }

        .pbold {
          font-size: 17px;
          margin-top: 0;
          .bold {
            color: #00233d;
            font-weight: bold;
            font-size: 18px;
          }
        }

        .hbold {
          display: flex;
          height: 80px;
          justify-content: start;
          align-items: center;
          h2 {
            color: #00233d;
            font-weight: bold;
            font-size: 16px;
            padding-bottom: 12px;
          }
        }

        .amenities-top {
          display: flex;
          h2 {
            margin-left: 15px;
          }
        }

        .amenities-bottom {
          display: flex;
          h2 {
            margin-left: 15px;
          }
        }

        .projectButton {
          background-color: #0c2c41;
          border: none;
          width: 150px;
          height: 45px;
          color: white;
          border-radius: 5px;
          font-family: "Manrope", sans-serif;
          font-size: 16px;
          letter-spacing: 1px;
          margin-right: 20px;
        }
        .projectButton:hover {
          background-color: #f9df35;
          color: #00233d;
          font-weight: bold;
        }

        .downloadButton {
          background-color: #f15b22;
          border: none;
          width: 150px;
          height: 45px;
          color: white;
          border-radius: 5px;
          font-family: "Manrope", sans-serif;
          font-size: 16px;
          letter-spacing: 1px;
          cursor: pointer;
        }
        .downloadButton:hover {
          background-color: #f9df35;
          color: #00233d;
          font-weight: bold;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .projectProfile {
    height: fit-content;
    background-color: #f7fafc;
    background-color: #f6f0e0;
    border-radius: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 10px;
    .title {
      text-align: center;
      font-size: 25px;
      margin-bottom: 0;
      padding-top: 0px;
    }
    hr {
      width: 80px;
      background: #f9df35;
      height: 2px;
      border: none;
    }

    .profile {
      // display: flex;
      width: 95%;
      margin-left: auto;
      margin-right: auto;
      height: fit-content;
      background-color: inherit;
      // padding-left: 20px;
      // padding-right: 20px;
      border-radius: 30px;
      .left {
        .projectlogo {
          margin-bottom: 20px;
          img {
            width: 200px;
            margin-left: 50%;
            transform: translate(-50%);
          }
        }
        .img1 {
          width: 100vw;
          height: 30vh;
        }
      }
      .right {
        // flex: 50%;
        text-align: center;
        h2 {
          margin-bottom: 0;
          font-family: "Cormorant Garamond", serif;
        }
        .ptext {
          color: gray;
          font-size: 17px;
          letter-spacing: 0.5px;
        }
        .tabFont {
          letter-spacing: 1px;
          font-family: "Manrope", sans-serif;
          font-weight: bold;
          font-size: 16px;
          margin-left: 10px;
          margin-top: 5px;
        }

        .pbold {
          font-size: 17px;
          margin-top: 0;
          .bold {
            color: #00233d;
            font-weight: bold;
            font-size: 18px;
          }
        }

        .hbold {
          display: flex;
          height: 80px;
          justify-content: start;
          align-items: center;
          h2 {
            color: #00233d;
            font-weight: bold;
            font-size: 16px;
            padding-bottom: 12px;
          }
        }

        .amenities-top {
          // display: flex;
          h2 {
            margin-left: 15px;
          }
        }

        .amenities-bottom {
          // display: flex;
          h2 {
            margin-left: 15px;
          }
        }

        .projectButton {
          background-color: #0c2c41;
          border: none;
          width: 150px;
          height: 45px;
          color: white;
          border-radius: 5px;
          font-family: "Manrope", sans-serif;
          font-size: 16px;
          letter-spacing: 1px;
          margin-right: 10px;
        }

        .downloadButton {
          background-color: #0c2c41;
          border: none;
          width: 150px;
          height: 45px;
          color: white;
          border-radius: 5px;
          font-family: "Manrope", sans-serif;
          font-size: 16px;
          letter-spacing: 1px;
          cursor: pointer;
        }
      }
    }
  }
}
