@media screen and (min-width: 768px) {
    .footer{
        height: 100vh;
        user-select: text;
    }
    
    .foot-top{
        height: 80%;
        background-color:  #212331;
        .foot-actions{
            display: flex;
            justify-content: space-evenly;
            padding-top: 100px;
            padding-left: 36px;
            padding-right: 36px;
    
            .foot-img{
                flex: 25%;
                margin-left: 20px;
                .foot-logo{
                    margin-top: 20vh;
                    width: 200px;
                }
            }
    
            .foot-contact{
                flex: 25%;
                h4{
                    color: white;
                    font-size: 19px;
                    letter-spacing: 1px;
                    font-weight: 500;
                }
                .address{
                    display: flex;
                    color: white;
                    .detail{
                        margin-left: 9px;
    
                        h6{
                            margin: 0;
                            font-size: 16px;
                        }
                        p{
                            font-size: 16px;
                            margin: 2px;
                            line-height: 19px;
                        }
                    }
                }
                .mobile{
                    display: flex;
                    color: white;
                    margin-bottom: 20px;
    
                    .icon2{
                        margin-top: 13px;
                    }
                    .detail2{
                        margin-left: 9px;
                        margin-top: 20px;
                        h6{
                            margin: 0;
                            font-size: 16px;
                        }
                        p{
                            font-size: 16px;
                            margin: 2px;
                            font-family: Verdana; 
                        }
                    }
                }
        
            }
            .foot-projects{
                h4{
                    color: white;
                    font-size: 19px;
                    letter-spacing: 1px;
                    font-weight: 500;
                }
                h6{
                    color: white;
                    font-size: 16px;
                    margin: 0;
                }
                flex: 25%;
            }
            .foot-location{
                h4{
                    color: white;
                    font-size: 19px;
                    letter-spacing: 1px;
                    font-weight: 500;
                }
                flex: 25%;
            }
        
    
        }
    
    
    }
    
    .foot-bottom{
        background-color: #181828;
        height: 20%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 36px;
        padding-right: 36px;
        .foot-bottom-left{
            font-size: 14px;
            line-height: 4px;
            color: white;
            .gold{
                color:  #fabd3c;
                cursor: pointer;
            }
        }
        .foot-bottom-right{
            font-size: 12px;
            color: white;
            margin-right: 100px;
        }
    }
    
    .foot-logo{
        height: fit-content;
        overflow: hidden;
        svg{
            width: 600px;
            overflow: hidden;
        }
    }
    
    
    .float{
        position:fixed;
        width:50px;
        height:50px;
        bottom:20px;
        right:30px;
        background-color:#25d366;
        color:#FFF;
        border-radius:50px;
        text-align:center;
        font-size:20px;
        box-shadow: 1px 1px 1px #999;
        z-index:100;
    }
    
    .my-float{
        margin-top:16px;
    }
}

@media only screen and (max-width: 767px) {
    .footer{
        height: 20vh;
        user-select: text;
    }
    
    .foot-top{
        display: none;

    }
    
    .foot-bottom{
        background-color: #181828;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        .foot-bottom-left{
            font-size: 14px;
            line-height: 4px;
            color: white;
            .gold{
                color:  #fabd3c;
                cursor: pointer;
            }
        }
        .foot-bottom-right{
            font-size: 12px;
            color: white;
        }
    }
    
 
}  

