@media screen and (min-width: 768px) {
  .ongoing {
    padding-top: 50px;
    height: 100%;
    background-color: #e5e5f7;
  opacity: 1;
  background-image:  radial-gradient(#9291fa 0.8500000000000001px, transparent 0.8500000000000001px), radial-gradient(#9291fa 0.8500000000000001px, #e5e5f7 0.8500000000000001px);
  background-size: 34px 34px;
  background-position: 0 0,17px 17px;
  
  .title {
      text-align: center;
      font-size: 25px;
      margin-bottom: 0;
      padding-top: 50px;
      padding-bottom: 0;
      color: #1f2f75;
    }
    hr {
      width: 80px;
      background: #f9df35;
      height: 2px;
      border: none;
    }
    .ongoinglist {
      // border: 1px solid red;
      width: 100vw;
      height: fit-content;
        .cards {
          display: flex;
          flex-wrap: wrap;
          padding: 20px;
          justify-content: space-evenly;
          .cardWrapper {
              width: 30%;
              background-color: #e5e5f7;
              position: relative;
  
              .img1 {
                  cursor: pointer;
                  height: 50vh;
                  border-radius: 20px;
                }
              .ongoinglogo{
                  position: absolute;
                  bottom: -50;
                  right: -50;
                  width: 300px;
                  background-color: rgb(255, 255, 255);
                  border-radius: 20px;
                  cursor: pointer;
              }
              // .hotelImg {
              //     width: 100%;
              //     height: 100%;
              //     object-fit: cover;
              //     cursor: pointer;
              //   }
            }
        }
        .projectButton {
          background-color: #f9df35; 
          border: none;
          width: 150px;
          height: 45px;
          font-weight: bold;
          color: #00233d;
          border-radius: 5px;
          font-family: 'Manrope', sans-serif;
          font-size: 16px;
          letter-spacing: 1px;
          cursor: pointer;
        }
    }
  }
  
}


