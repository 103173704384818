@media screen and (min-width: 768px) {
  .profile {
    background-color: #fdd15f;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
  }
  .whymsr {
    height: 60vh;
    display: flex;

    .whymsr-right {
      margin-top: 17px;
      flex: 45%;
      padding-right: 36px;
      margin-left: 25px;
      margin-bottom: 17px;
      h1 {
        margin-bottom: 0;
        font-size: 2.8rem;
        font-weight: bold;
        color: #1f2f75;
        font-family: "Cormorant Garamond", serif;
      }
      p {
        font-size: 1.5vw;
        color: rgba(49, 49, 49, 0.932);
        line-height: 30px;
        letter-spacing: 0.5px;
      }
    }

    .whymsr-left {
      flex: 40%;
      text-align: center;

      .whymsr-img {
        margin-top: 20px;
        width: 230px;
      }
    }
  }

  .stats {
    background-color: #1f2f75;
    height: 30%;
    display: flex;
    text-align: center;
    align-items: center;
    border-radius: 60px;
    margin-top: 10px;
    div {
      flex: 25%;
      display: block;
    }
    h1 {
      color: white;
      margin: 0;
      font-size: 3rem;
    }
    p {
      color: white;
      font-size: 15px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .profile {
    background-color: #fdd15f;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
  }
  .whymsr {
    height: fit-content;
    // text-align: center;
    .whymsr-right {
      margin-top: 17px;
      padding-right: 36px;
      margin-left: 25px;
      margin-bottom: 17px;
      h1 {
        text-align: center;
        font-size: 2.8rem;
        font-weight: 900;
        color: #2f3d7e;
        font-family: "Cormorant Garamond", serif;
      }
      p {
        text-align: justify;
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        color: rgba(49, 49, 49, 0.932);
        letter-spacing: 0.5px;
      }
    }

    .whymsr-left {
      text-align: center;

      .whymsr-img {
        margin-top: 20px;
        width: 200px;
      }
    }
  }

  .stats {
    background-color: #1f2f75;
    height: fit-content;
    display: flex;
    text-align: center;
    border-radius: 60px;
    // margin-top: 10px;
    padding: 10px;
    div {
      flex: 25%;
      display: block;
      margin-top: 5vw;
    }
    h1 {
      color: white;
      margin: 0;
      font-size: 30px;
    }
    p {
      color: white;
      font-size: 12px;
    }
  }
}
