


@media screen and (min-width: 768px) {
    .cta{
        display: flex;
        padding-top: 100px;
        height: 80vh;
    }
}

@media only screen and (max-width: 767px) {
    .cta{
        padding-top: 10vh;
        height: fit-content;
        display: flex;
        flex-direction: column;
        font-family: 'Manrope', sans-serif;
    }
}  