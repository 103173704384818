@media screen and (min-width: 768px) {
  .navbar {
    // background-color: #0c2c41c9;
    background-color: rgba(228, 220, 220, 0.582);
    height: 100px;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 10;
    width: 100%;
    // padding-left: 55px;
    padding-right: 55px;
    backdrop-filter: blur(5px);
    justify-content: space-between;
    // font-family: "Poppins", sans-serif;

    .logo {
      width: 370px;
    }

    .actions {
      ul {
        display: flex;
        list-style: none;
        color: black;
        font-weight: bold;
        margin-right: 40px;
        li {
          padding: 3px;
          font-size: 1rem;
          margin-right: 45px;
          cursor: pointer;
          position: relative;
          .projectsoptions {
            position: absolute;
            background-color: #fce092;
            border-radius: 10px;
            border: 1px solid #efebeb;
            width: 200px;
            padding-left: 20px;
            margin-top: 0px;
            p {
              color: rgb(88, 86, 86);
            }
            p:hover {
              color: #f15b22;
            }
          }
        }
        li:hover {
          color: #facd39;
          color: #ff4800;
        }

        .but {
          color: white;
          text-align: center;
          font-size: 18px;
          font-weight: bold;
          background-color: #f15b22;
          border-color: #f15b22;
          cursor: pointer;
          border-radius: 4px;
          letter-spacing: 2px;
          padding: 5px;
        }
        .but:hover {
          border-color: #facd39b7;
        }
      }
    }
  }

  .navbar-mobile {
    display: none;
  }
  .sidebarmobile {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .navbar {
    display: none;
  }

  .navbar-mobile {
    z-index: 10;
    width: 100vw;
    height: 10vh;
    background-color: #efebeb;
    position: fixed;
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    .logoo {
      margin-left: 20px;
      width: 100px;
      margin-top: 10px;
    }
  }

  .butt {
    font-family: "Manrope", sans-serif;
    background-color: inherit;
    height: 4vh;
    margin-left: 25vw;
    border: 1px solid #14151679;
    font-weight: bold;
    align-items: center;
    color: #141516ea;
    border-radius: 5px;
  }

  .burger div {
    width: min(7vw, 25px);
    height: 3px;
    background-color: black;
    margin: 6px;
    margin-left: auto;
    margin-right: 8vw;
  }

  .burger:hover {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  .sidebarmobile {
    font-family: "Manrope", sans-serif;
    z-index: 3;
    position: fixed;
    width: 0;
    height: 100%;
    background-color: #efebeb;
    overflow-x: hidden;
    transition: 0.4s;
    margin-top: 10vh;
    font-size: 12px;
  }

  .side-listmobile {
    margin-left: min(25vw, 30px);
    margin-top: 2.5vh;
    color: #011f4c;
  }

  .side-listmobile h1 {
    color: #011f4c;
    margin-bottom: 0px;
  }

  .side-listmobile h1:hover {
    cursor: pointer;
  }

  h1:hover .list-letter {
    color: #ff3366;
  }

  .sidebarmobile hr {
    display: none;
    width: min(130px, 120px);
    margin-left: 0px;
    margin-top: 0px;
    border: none;
    background: linear-gradient(to left, #011f4c, #ff3366);
    height: 2px;
  }

  .active hr {
    display: block;
  }
}
