body {
  margin: 0;
  /* font-family: "Manrope", sans-serif; */
  font-family: "Poppins", sans-serif;
  background: #f7fafc;
  user-select: none;
  position: relative;
}

body.loading {
  overflow: hidden;
  height: 100vh;
}

html {
  scroll-behavior: smooth;
  /* font-family: "Manrope", sans-serif; */
  font-family: "Poppins", sans-serif;
}
