@font-face {
  font-family: khaleef;
  src: url("../../../fonts/Khaleefa.otf") format("opentype");
}

@media screen and (min-width: 768px) {
  .welcome {
    flex: 35%;
    width: 100%;
    font-family: Cormorant;
    background-image: url("../../../assets/toranam.png"),
      url("../../../assets/toranamdown.png"), url("../../../assets/wave1.png");
    background-repeat: no-repeat;
    background-size: 110%, 100%;
    background-position: 50% -12%, 20% 10%, 100% 120%;
    /* background-color: rgba(228, 220, 220, 0.582); */
    /* background: linear-gradient(-45deg,#d2d523, #18374bda, #18374bd2, #f9e034); */
    /* background-size: 400% 400%; */
    /* animation: gradient 15s ease infinite; */
    background-color: #efebeb;
    opacity: 0.8;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .welcome-cta {
    margin-left: 25%;
    margin-right: 12%;
    margin-top: 28%;
  }

  .welcome-title {
    /* font-family: "Manrope", sans-serif; */
    font-family: "Cormorant Garamond", serif;
    font-size: 3.5rem;
    font-weight: 700;
    font-family: "Taviraj", serif;
    font-size: 2.7rem;
    font-weight: 600;
    margin: 0;
    color: #1f2f75;
    color: #2f3d7e;
    color: #0b6d20;
  }

  .welcome-para {
    margin: 0;
    font-weight: lighter;
    color: rgb(0, 0, 0);
    color: grey;
    font-size: 30px;
  }

  .welcome hr {
    border: none;
    height: 2px;
    width: 200px;
    margin-left: 0;
    background: linear-gradient(to right, #f9df35, #f9df3513);
  }

  .welcome p {
    font-size: 19px;
    font-family: "Manrope", sans-serif;
    color: rgba(255, 255, 255, 0.863);
    color: grey;
  }

  .welcome button {
    font-family: "Manrope", sans-serif;
    /* background-color: #facd39; */
    background-color: #f15d22;
    border: none;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 2px;
    text-shadow: black;
    cursor: pointer;
    border-radius: 2px;
    width: 210px;
    height: 50px;

    /* background-color: transparent;
        border-color: #facd39;
        color: #facd39;   */
  }

  .welcome button:hover {
    background-color: #ff4800;
  }
}

@media only screen and (max-width: 767px) {
  .welcome {
    font-family: Cormorant;
    background-image: url("../../../assets/toranam.png"),
      url("../../../assets/toranamdown.png"), url("../../../assets/wave1.png");
    background-repeat: no-repeat;
    background-size: 110%, 100%;
    background-position: 50% -5%, 20% 5%, 100% 100%;
    /* background-color: rgba(228, 220, 220, 0.582); */
    /* background: linear-gradient(-45deg,#d2d523, #18374bda, #18374bd2, #f9e034); */
    /* background-size: 400% 400%; */
    /* animation: gradient 15s ease infinite; */
    background-color: #efebeb;
    opacity: 0.8;
    padding-bottom: 100px;
    height: 50vh;
    text-align: center;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .welcome-cta {
    margin-left: 23%;
    margin-right: 12%;
    margin-top: 35%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 40%;
  }

  .welcome-title {
    /* font-family: "Manrope", sans-serif; */
    font-family: "Cormorant Garamond", serif;
    font-size: 43px;
    font-size: 50px;
    font-weight: bold;
    margin: 0;
    color: #1f2f75;
    color: #0b6d20;
    height: 10vh;
  }

  .welcome-para {
    margin: 0;
    font-weight: lighter;
    color: rgb(0, 0, 0);
    color: grey;
    font-size: 26px;
  }

  .welcome hr {
    border: none;
    height: 2px;
    width: 200px;
    margin-left: 0;
    background: linear-gradient(to right, #f9df35, #f9df3513);
    margin-top: 40px;
    margin-top: 20px;
  }

  .welcome p {
    font-size: 19px;
    font-family: "Manrope", sans-serif;
    color: rgba(255, 255, 255, 0.863);
    color: grey;
  }

  .welcome button {
    font-family: "Manrope", sans-serif;
    /* background-color: #facd39; */
    background-color: #f15d22;
    border: none;
    /* padding: 5px; */
    padding-left: 10px;
    padding-right: 10px;
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 2px;
    text-shadow: black;
    cursor: pointer;
    border-radius: 2px;
    width: 210px;
    height: 50px;

    /* background-color: transparent;
        border-color: #facd39;
        color: #facd39;   */
  }
}
