@media screen and (min-width: 768px) {
    .projectFull{
        overflow: hidden;
        font-family: raleway;
        
            .projectIntro{
                position: relative;
        
                .plotlogo{
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%);
                    width: 500px;
                    top: 7%;
                }
                .plotimg{
                    width: 100vw;
                }
            }
            .projectInfo2{
                display: flex;
                height: 80vh;
                background-image: url("../../../assets/greenmedlogo.png");
                background-repeat: no-repeat;
                background-position: 1000 200;
                background-size: 900px;
                margin-bottom: 0;
                .left{
                    flex: 30%;
                    img{
                        width: 250px;
                        margin-left: 50%;
                        transform: translate(-50%);
                        margin-top: 80px;
                    }
                }
                .right{
                    padding: 10px;
                    flex: 70%;
               }
            }
            .salient{
                margin-top: 0;
                height: 100vh;
                background-image: url("../../../assets/background.jpg");
                padding-bottom: 50px;
                h1{
                    text-align: center;
                    padding-top: 40px;
                    padding-bottom: 40px;
                }
                .salienticons{
                    display: flex;
                    justify-content: space-around;
                    margin-top: 20px;
                    .icon{
                        width: 200px;
                        text-align: center;
                        padding: 10px;
                        justify-content: center;
                        align-items: center;
                        background-color: white;
                        border-radius: 10px;
                    }
                }
            }
            .hotelImages {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                padding: 20px;
                .hotelImgWrapper {
                    width: 33%;
        
                    .hotelImg {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        cursor: pointer;
                      }
                  }
              }
        
        }
}

@media only screen and (max-width: 767px) {
    .projectFull{
        overflow: hidden;
        font-family: 'Manrope', sans-serif;
        
            .projectIntro{
                position: relative; 
        
                .plotlogo1{
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%);
                    width: 300px;
                    top: 25%;
                }
                .plotimg1{
                    width: 100vw;
                    height: 85vh;
                }
            }
            .projectInfo1{
                // display: flex;
                height: fit-content;
                background-image: url("../../../assets/lotusonly.png");
                background-repeat: no-repeat;
                background-position: 890 10;
                background-size: 900px;
                margin-bottom: 0;
                .left{
                    // flex: 30%;
                    img{
                        width: 250px;
                        margin-left: 50%;
                        transform: translate(-50%);
                        margin-top: 80px;
                    }
                }
                .right{
                    padding: 20px;
                    // flex: 70%;
               }
            }
            .salient1{
                margin-top: 0;
                height: fit-content;
                background-image: url("../../../assets/background.jpg");
                padding-bottom: 50px;
                h1{
                    text-align: center;
                    padding-top: 40px;
                    padding-bottom: 40px;
                }
                .salienticons1{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    margin-top: 10px;
                    height: fit-content;
                    .icon{
                        width: 30vw;
                        text-align: center;
                        padding: 10px;
                        justify-content: center;
                        align-items: center;
                        background-color: white;
                        border-radius: 10px;
                        margin-bottom: 10px;
                    }
                }
            }
            .hotelImages {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                padding: 20px;
                .hotelImgWrapper {
                    width: 33%;
        
                    .hotelImg {
                        // width: 100%;
                        height: 40vh;
                        object-fit: cover;
                        cursor: pointer;
                      }
                  }
              }
        
        }
}  




