
.imageshow{
    .hotelImages {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 20px;
        .hotelImgWrapper {
            width: 33%;
            .hotelImg {
                width: 100%;
                height: 100%;
                object-fit: cover;
                cursor: pointer;
              }
          }
      }
}

.viewer{
    #ReactSimpleImageViewer{
        z-index: 12;
    }
}

