
@media screen and (min-width: 768px) {
    .whatsapp{
        position: absolute;
        right: 0;
        position: fixed;
        bottom: 0;
        z-index: 2;
        img{
            width: 150px;
            cursor: pointer;
        }
        img:hover{
            width: 155px;
        }
    }
    .mobi{
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .whatsapp{
        position: absolute;
        right: -30;
        position: fixed;
        bottom: 100;
        z-index: 2;
        img{
            width: 150px;
            cursor: pointer;
        }
        img:hover{
            width: 155px;
        }
    }
    .desk{
        display: none;
    }
}  